<template>
  <div class="content_yd">
    <div class="content_wrap_yd">
      <div class="content_crumbs_yd">
        <el-breadcrumb separator-icon="ArrowRight">
          <el-breadcrumb-item :to="{ path: '/' }">当前位置：集团要闻</el-breadcrumb-item>
          <el-breadcrumb-item>正文</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class=" content_content_yd">
        <div class="content_content_title_yd">
          {{ bulletinData.title }}
        </div>

        <div class="content_content_crumbs_yd">
          <div class="content_content_crumbs_time_yd">
            <img src="./../pic/timer.png" alt="">
            发布时间：{{bulletinData.publishMediaTime }}
          </div>
        </div>

        <div class="content_content_item_wrap_yd">
          <div class="content_content_item_yd">
            <p v-html="bulletinData.content"></p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { getDetail } from "@/api/index";
export default {
  setup() {
    const router = useRouter();
    const idValue = ref(null)
    const bulletinData = ref({})
    const NewsData = async () => {
      const res = await getDetail(idValue.value)
      bulletinData.value = res.data
    }
    onMounted(async () => {
      idValue.value = router.currentRoute.value.query.id;
      await NewsData()
    })
    return {
      idValue,
      NewsData,
      bulletinData
    };
  },
};

</script>

<style scoped>
@import url("./conMes.css");
</style>

