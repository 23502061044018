<template>
  <div class="head_box">
    <imgHead />
    <Header />
    <contentMesage />
    <NavFooter />
  </div>
</template>

<script>
import imgHead from "./compontents/img/imgHead.vue";
import Header from "./compontents/Header/Header.vue";
import NavFooter from "./compontents/Footer/Footer.vue";
import contentMesage from "./compontents/contentMesage/conMes.vue";
import { mainStore } from "@/store";
import { ref } from "vue";
export default {
  components: { Header, NavFooter, imgHead, contentMesage },
  setup() {
    const store = mainStore();
    const webTitle = ref("");
    const aaa = store.getWebData;
    aaa();
    return {
      webTitle,
    };
  },
  methods: {
  },
};
</script>