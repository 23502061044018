<template>
  <div class="head_box">
    <div class="head_img">
      <div class="head_logo">
        <img :src="webData.siteLogo ? webData.siteLogo.link : './pic/bnannerLogo.png'" alt="" style="width: 100%;height:100%;object-fit: cover;">
      </div>
      <div class="head_text"> 山东英大招投标集团</div>
    </div>
  </div>
</template>
<script>
import { computed, reactive } from "vue";
import { storeToRefs } from "pinia";
import { mainStore } from "@/store";
export default {
  setup() {
    const store = mainStore();
    const webData = computed(() => storeToRefs(store).webData)
    console.log(webData.value, 'webData.siteLogo');
    const data = reactive({ webData });
    return {
      ...data,
      store,
    }
  },
  methods: {

  },
};
</script>
<style scoped>
@import url("./imgHead.css");
</style>